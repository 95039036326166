/*
  POSTS TEMPLATE
  A template to display Article Posts
*/

import React from 'react'
import { graphql } from 'gatsby'

import PostContextProvider from '@base/utils/contexts/posts-context'
import PageLayout from '@base/layout/page-layout'

import { getGeneralData } from '@api/general-data'
import PostContent from '@elements/post-content'

const DynamicArticle = ({ data, pageContext }) => {
  const generalData = getGeneralData(pageContext, data)
  const minifiedPageContext = generalData.pageContext
  const globalData = generalData.globalData
  const modalData = generalData.modalData
  const { title } = data.pageData
  const stringsSlugs = globalData.stringsSlugs
  const parentPages = [
    {
      'slug': stringsSlugs.article,
      'shortName': stringsSlugs.articlesTitle
    },
  ]

  return (
    <PageLayout
      pageContext={minifiedPageContext}
      modalData={modalData}
      breadcrumbs={{
        enableGlobalBreadcrumbs: true,
        disablePageBreadcrumbs: false,
        postTitle: title,
        postParent: parentPages,
        strings: { back: globalData.stringsActions.back, home: globalData.stringsSlugs.homeTitle }
      }}
    >
      <PostContextProvider>
        <PostContent
          pageContext={minifiedPageContext}
          globalData={globalData}
          data={data.pageData}
          directory={stringsSlugs.article}
        />
      </PostContextProvider>
    </PageLayout>
  )
}

export default DynamicArticle

export const query = graphql`
  query DynamicArticleQuery($id: String!) {
    pageData: strapiArticle(id: { eq: $id }) {
      ...Articles
    }
  }
`

export { Head } from '@base/layout/head'