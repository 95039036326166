import React, { useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import {
  motion,
  useAnimation,
  useScroll,
  useTransform,
  useSpring,
  useReducedMotion,
} from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { StaticImage } from 'gatsby-plugin-image'

import { localizePath } from '@base/utils/helpers/localization/localize'
import { formatISOdate } from '@base/utils/helpers/date-time'
import MixedMedia from '@base/parts/mixed-media'
import Markdown from '@base/parts/markdown'
import ReadingTime from '@base/utils/helpers//reading-time'
import SocialShare from '@base/elements/social-share'

const fadeInBlur = {
  visible: { opacity: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0 },
}

const PostContent = (props) => {
  const section = useRef(null)
  const data = props.data
  const directory = props.directory
  const pageContext = props.pageContext
  const globalData = props.globalData
  const publicationDate =
    data.publication_date_display && data.publication_date_display !== null
      ? data.publication_date_display
      : data.publishedAt
  const postDate = formatISOdate(publicationDate, data.locale)
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const articleURL = localizePath({
    ...pageContext,
    slug: `${directory}/${pageContext.slug}`,
  })
  const siteURL = 'https://meetyourmakergame.com'
  const stringsLabels = globalData.stringsLabels

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  // UI elements animations
  const prefersReducedMotion = useReducedMotion()
  const { scrollYProgress } = useScroll({
    target: section,
    offset: [data.displayFilters ? '0%' : '-100%', '100%'],
  })
  const translateX = useSpring(scrollYProgress, {
    stiffness: 70,
    damping: 30,
  })
  const topUIAnim = useTransform(translateX, [0, 1], [200, 500])
  const bottomUIAnim = useTransform(translateX, [0, 1], [200, -200])

  return (
    <article ref={section} className="relative">
      <section
        className={`article-hero relative flex flex-col items-center justify-center overflow-hidden text-white`}
      >
        <StaticImage
          src="../../assets/img/MyM-website-article-background.jpg"
          alt=""
          className="gatsby-absolute gatsby-image-mask-large pointer-events-none top-0 z-0 -mt-64 h-full w-auto min-w-full opacity-50 blur-[4px]"
          backgroundColor="#004242"
          loading="lazy"
        />
        <StaticImage
          src="../../assets/img/MyM-yellow-dust-texture.png"
          alt=""
          className="gatsby-absolute pointer-events-none left-0 top-0 h-auto w-auto rotate-180"
          loading="lazy"
        />
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={fadeInBlur}
          className={`relative z-10 mb-12 mt-24 h-auto w-full overflow-hidden px-10 lg:mt-32 lg:w-3/4 lg:px-0 xxl:w-4/6`}
        >
          {data.mainImage && (
            <div className="relative w-full lg:pb-2">
              <MixedMedia
                media={data.mainImage}
                className="h-full w-full border-[3px] border-solid border-secondary object-cover"
              />
              <div className="crop-shape-large pointer-events-none absolute bottom-[3px] right-[3px] z-10 w-full bg-secondary pt-20 lg:pt-80">
                <StaticImage
                  src="../../assets/img/MyM-background-texture-orange.jpg"
                  alt=""
                  className="gatsby-absolute pointer-events-none top-0 z-under h-full w-auto"
                  backgroundColor="#FF6600"
                  loading="lazy"
                />
              </div>
            </div>
          )}
        </motion.div>
        <div className="relative mb-12 w-full px-12 lg:w-3/4 lg:px-0 xxl:w-1/2">
          <motion.div
            style={{
              x: !prefersReducedMotion && topUIAnim,
            }}
            className={`gatsby-absolute pointer-events-none -right-96 -top-[200px] z-0 lg:-right-2/3 lg:-top-[300px]`}
          >
            <StaticImage
              src="../../assets/img/MyM-texture-diagonal-stripe-large.png"
              alt=""
              className="gatsby-object-contain h-[275px] w-auto max-w-full lg:h-[350px]"
              loading="lazy"
            />
          </motion.div>
          {data.article_category && (
            <Link
              to={localizePath({
                ...pageContext,
                slug: `${directory}/?category=${data.article_category.slug}`,
              })}
              className="button-filter crop-tag filter-active inline-flex items-center justify-center"
            >
              {data.article_category.name}
            </Link>
          )}
          <h1 className="smaller mb-12 mt-12 leading-tight">{data.title}</h1>
          <div className="flex flex-col justify-between md:flex-row md:items-center">
            <p className="font-body text-base font-bold uppercase">
              <time dateTime={postDate}>{postDate}</time>
              <span className="mx-4">·</span>
              {data.content && (
                <ReadingTime
                  text={
                    data.content.data !== null ? data.content.data.content : ''
                  }
                  strings={[
                    stringsLabels.minuteRead,
                    stringsLabels.minutesRead,
                  ]}
                />
              )}
            </p>
            <div className="-ml-3 mt-8 space-x-4 md:m-0">
              <SocialShare
                locale={data.locale}
                title={data.title}
                url={`${siteURL}${articleURL}/`}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="article-content relative z-0 flex flex-col items-center justify-center px-12 font-body text-white lg:px-0">
        <div className="rich-text post relative z-10 w-full pb-24 text-base lg:w-3/4 lg:pb-96 xxl:w-1/2">
          {data.content && (
            <Markdown
              children={
                data.content.data !== null ? data.content.data.content : ''
              }
              globalData={globalData}
              type="longText"
            />
          )}
        </div>
        <motion.div
          style={{
            x: !prefersReducedMotion && bottomUIAnim,
          }}
          className={`gatsby-absolute pointer-events-none -bottom-24 -left-1/2 z-0 lg:-left-1/4 lg:bottom-80`}
        >
          <StaticImage
            src="../../assets/img/MyM-texture-diagonal-stripe-large.png"
            alt=""
            className="gatsby-object-contain h-[275px] w-auto max-w-full lg:h-[350px]"
            loading="lazy"
          />
        </motion.div>
      </section>

      <div
        className={`pointer-events-none absolute left-0 top-0 z-[-2] h-[110%] w-full bg-gradient-to-t from-black to-primary`}
      >
        <StaticImage
          src="../../assets/img/MyM-yellow-dust-texture.png"
          alt=""
          className="gatsby-absolute pointer-events-none bottom-0 left-0 h-auto w-full"
          loading="lazy"
        />
      </div>
    </article>
  )
}

export default PostContent
